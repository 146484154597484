var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-signup" }, [
    _vm._m(0),
    _c("div", { staticClass: "login-signup-form-wrapper" }, [
      _c("img", {
        staticClass: "login-signup-form-logo",
        attrs: { src: require("@/assets/logo/logo-text.svg"), alt: "" }
      }),
      _c(
        "div",
        { staticClass: "login-signup-form" },
        [
          _c("h2", { staticClass: "login-signup-form-title" }, [
            _vm._v("A little bit about you.")
          ]),
          _c("p", { staticClass: "login-signup-form-subtitle" }, [
            _vm._v(
              " Let's get your account set up by checking your first and last name! "
            )
          ]),
          _c("AvatarUpload", {
            attrs: { image: _vm.imageUrl, width: 512, "max-size": 5 },
            on: {
              input: function(url, img) {
                _vm.imageUrl = url
                _vm.image = img
              }
            }
          }),
          _c("TextInput", {
            attrs: {
              title: "First name",
              "min-length": 1,
              validate: true,
              placeholder: "First name can't be empty"
            },
            model: {
              value: _vm.firstName,
              callback: function($$v) {
                _vm.firstName = $$v
              },
              expression: "firstName"
            }
          }),
          _c("TextInput", {
            attrs: {
              title: "Last name",
              "min-length": 1,
              validate: true,
              placeholder: "Last name can't be empty"
            },
            model: {
              value: _vm.lastName,
              callback: function($$v) {
                _vm.lastName = $$v
              },
              expression: "lastName"
            }
          }),
          _c("LanguageSelect", {
            attrs: { title: "Language" },
            model: {
              value: _vm.language,
              callback: function($$v) {
                _vm.language = $$v
              },
              expression: "language"
            }
          }),
          _c("Button", {
            attrs: {
              text: "Launch the app",
              loading: _vm.patchLoading,
              disabled: !_vm.firstName || !_vm.lastName
            },
            on: { click: _vm.handleOnboardingCompleted }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-signup-bg" }, [
      _c("img", {
        staticClass: "login-signup-bg-img",
        attrs: { src: require("@/assets/login-bg.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }