<template>
  <div class="login-signup">
    <div class="login-signup-bg">
      <img src="@/assets/login-bg.svg" alt="" class="login-signup-bg-img" />
    </div>
    <div class="login-signup-form-wrapper">
      <img
        src="@/assets/logo/logo-text.svg"
        alt=""
        class="login-signup-form-logo"
      />
      <div class="login-signup-form">
        <h2 class="login-signup-form-title">A little bit about you.</h2>
        <p class="login-signup-form-subtitle">
          Let's get your account set up by checking your first and last name!
        </p>
        <AvatarUpload
          :image="imageUrl"
          :width="512"
          :max-size="5"
          @input="
            (url, img) => {
              imageUrl = url
              image = img
            }
          "
        />
        <TextInput
          v-model="firstName"
          title="First name"
          :min-length="1"
          :validate="true"
          placeholder="First name can't be empty"
        />
        <TextInput
          v-model="lastName"
          title="Last name"
          :min-length="1"
          :validate="true"
          placeholder="Last name can't be empty"
        />
        <LanguageSelect v-model="language" title="Language" />
        <Button
          text="Launch the app"
          :loading="patchLoading"
          :disabled="!firstName || !lastName"
          @click="handleOnboardingCompleted"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AvatarUpload from '@c/library/AvatarUpload.vue'
import Button from '@c/library/Button.vue'
import LanguageSelect from '@c/library/LanguageSelect.vue'
import TextInput from '@c/library/TextInput.vue'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default Vue.extend({
  components: { AvatarUpload, LanguageSelect, Button, TextInput },
  data() {
    return {
      showLoginForm: false,
      loading: false,
      firstName: '',
      lastName: '',
      image: undefined,
      imageUrl: '',
      patchLoading: false,
      language: ''
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser
    })
  },
  mounted() {
    if (this.currentUser.first_name) {
      this.firstName = this.currentUser.first_name
    }
    if (this.currentUser.last_name) {
      this.lastName = this.currentUser.last_name
    }
    if (this.currentUser.language) {
      this.language = this.currentUser.language
    }
  },
  methods: {
    ...mapActions(['patchUser', 'updateAvatar']),
    async handleOnboardingCompleted() {
      if (this.firstName !== '' && this.lastName !== '') {
        this.patchLoading = true
        await this.patchUser({
          id: this.currentUser.uuid,
          data: {
            first_name: this.firstName,
            last_name: this.lastName,
            language: this.language
          }
        })
        try {
          if (this.image) {
            await this.updateAvatar(this.image)
          }
          this.patchLoading = false
          this.$router.push({ name: 'home' })
        } catch (e) {
          this.$toast.error(e, 'uploading image')
          this.patchLoading = false
        }
      } else {
        this.$toast.danger(
          this,
          'Incomplete information',
          'Please fill in all your information in a correct way.'
        )
      }
    }
  }
})
</script>
<style scoped lang="scss">
.login-signup {
  display: grid;
  grid-template-columns: 50vw auto;
  height: 100vh;

  &-bg {
    height: 100%;
    width: 100%;
    overflow: hidden;

    &-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 0 5rem;
    gap: 1rem;

    &-wrapper {
      display: grid;
      grid-template-rows: 1fr auto 1fr;
      gap: 0.5rem;
      overflow-y: auto;
    }

    &-logo {
      height: 2rem;
      margin: 2rem 0 0 2rem;
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
    }

    &-subtitle {
      color: #60666b;
    }
  }
}
</style>
